// i18next-extract-mark-ns-start account

import React from "react";
import { Link, graphql } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LogoutButton from "@components/LogoutButton";
import Page from "@components/layout/Page";
import SEO from "@components/layout/SEO";
import PageSection from "@components/PageSection";
import { useTranslation } from "react-i18next";
import Banner from "@components/Banner";

const Account = ({
    data
}: {
    data: any;
}) => {
    const { t } = useTranslation();
    const { user, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    if (!user) return <p>ERROR - no user!</p>

    return (
        <Page>
            <SEO
                title={t('seo_title')}
                description={t('seo_description')}
            />

            <Banner
                title={t("title")}
                image={{
                    src: data.banner.childImageSharp.gatsbyImageData,
                    alt: '',
                }}
            />

            <PageSection>
                <div className="col xl8 m12 m-spacer">
                    <p>Email: {user.email}</p>
                    <LogoutButton />
                </div>
            </PageSection>


        </Page>
    )
}

export default withAuthenticationRequired(Account);

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["account", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/software-hero.jpg" }) {
		...imageBreaker
	}
}
`;

